<template>
  <div id="app">
    <RouterView />
  </div>
</template>

<script>
import { RouterView } from "vue-router";
export default {
  components: {
    RouterView,
  },
  created() {
    this.NamePath();
  },
  data() {
    return {
      idStore: "",
    };
  },
  methods: {
    NamePath() {
      const url = window.location.pathname;
      const urlSplit = url.split("/");
      if (urlSplit && urlSplit[1]) {
        this.idStore = urlSplit[1];
        this.getDataId(this.idStore);
      } else {
        this.$router.push({
          path: `/komercia`,
        });
        this.getDataId("komercia");
      }
    },
    async getDataId(value) {
      if (value) {
        const { success } = await this.$store.dispatch("GET_SETTINGS_ID", {
          idStore: value,
        });
        if (success) {
          this.isStore = true;
        } else {
          console.log("Error StoreLoad ID");
        }
      }
    },
  },
};
</script>
