export default {
  SET_CURRENTSETTING(state, value) {
    if (value) {
      if (value.component) {
        switch (value.component) {
          case "settingGeneral":
            state.settings.general_setting = value.setting;
            break;
          case "header":
            state.settings.header_setting = value.setting;
            break;
          case "information":
            state.settings.body_setting = value.setting;
            break;
        }
      }
    }
  },
  SET_SETTING(state, value) {
    state.settings = value;
  },
};
