// import { faSearch } from '@fortawesome/free-solid-svg-icons'
const URL_KOMERCIA_AWS = "https://api-helpdesk-link.komercia.co";

export default {
  settings: [],
  urlKomerciaAws: URL_KOMERCIA_AWS,
  configAxios: {
    headers: {
      "content-type": "application/json",
      Authorization: "",
      "Access-Control-Allow-Origin": "*",
      Access: "application/json",
    },
  },
};
