import axios from "axios";

export default {
  async GET_SETTINGS_ID({ state, commit }, params) {
    if (params && params.idStore) {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${state.urlKomerciaAws}/api/links/public/${params.idStore}`,
          headers: state.configAxios.headers,
        });
        if (data) {
          commit("SET_SETTING", data.body[0]);
          return { success: true, data: data.body[0] };
        }
      } catch (err) {
        return { success: false, data: err.response };
      }
    } else {
      console.log("No tiene IdStore");
    }
  },
};
